import React, { useState, useRef } from "react"
import * as styles from "./story-form.module.css"
import Arrow from "./arrow"
import { Container, Row, Col } from "react-bootstrap"
import { BsArrowClockwise } from "react-icons/bs"

const StoryForm = () => {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [submitStatus, setSubmitStatus] = useState(false)
  const [profession, setProfession] = useState("")
  const [date, setDate] = useState("")
  const [story, setStory] = useState("")
  const [image, setImage] = useState("")
  const [yourFirst, setYourFirst] = useState("")
  const [yourLast, setYourLast] = useState("")
  const [relationship, setRelationship] = useState("")

  const imageInput = useRef(null)

  const formEndpoint = "https://seiuuhw.tfaforms.net/responses/processor"

  const submitForm = () => {
    // Trying to avoid double clicks here.
    const checkNull = !(firstName && lastName && yourFirst && yourLast && email)
    if (submitStatus === "loading" || checkNull) {
      return // don't send this twice.
    }

    var formData = new FormData()
    formData.append("tfa_2", firstName)
    formData.append("tfa_1", lastName)
    formData.append("tfa_6", profession || "null")
    formData.append("tfa_10", date || "null")
    formData.append("tfa_21", image || "null")
    formData.append("tfa_9", story || "null")
    formData.append("tfa_12", yourFirst)
    formData.append("tfa_13", yourLast)
    formData.append("tfa_15", email)
    formData.append("tfa_16", relationship || "null")
    formData.append("tfa_23", "7016S000001nNUmQAM")
    formData.append("tfa_dbFormId", 168)
    formData.append("tfa_dbVersionId", 37)
    formData.append("tfa_dbControl", "24e7048b312454852983d22037a99495")
    // This is a POST request to the comments endpoint. The body is sent as a JSON string.
    fetch(formEndpoint, {
      method: "POST",
      mode: 'no-cors',
      // headers: {
      //   "Content-Type": "multipart/form-data",
      // },
      body: formData,
    })
    .then(function (res) {
        setSubmitStatus("success")
    }, function (e) {
      setSubmitStatus("error")
    });
  }
  const resetForm = () => {
    setFirstName("")
    setLastName("")
    setEmail("")
    setSubmitStatus(false)
    setProfession("")
    setDate("")
    setStory("")
    setImage("")
    setYourFirst("")
    setYourLast("")
    setRelationship("")
  }
  // Renders the comment form elements.
  const renderCommentForm = (
    <div>
      <Container style={{paddingBottom: "12%"}}>
        <form
          onSubmit={e => {
            e.preventDefault()
            setSubmitStatus("loading")
          }}
        >
          <input type="hidden" name="botField" />
          <Row
            style={{
              textAlign: "center",
              justifyContent: "center",
              padding: "0 5%",
            }}
          >
            <Col xs="12" lg={{ span: "10", offset: "1" }}>
              <h2>Share the Legacy of a Healthcare Worker Lost to COVID-19</h2>
              <p>
                Forever Essential is committed to honoring the memory of
                California healthcare workers lost to COVID-19. If you know of a
                healthcare worker who lost their life to COVID-19, please share
                their story below.
              </p>
            </Col>
          </Row>
          <Row>
            <Col
              xs="12"
              md="6"
              lg={{ span: "4", offset: "2" }}
              className={styles.field}
            >
              <input
                type="text"
                name="firstName"
                id="firstName"
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
                placeholder="Their Name (First)*"
                required
              />
            </Col>
            <Col xs="12" md="6" lg={{ span: "4" }} className={styles.field}>
              <input
                type="text"
                name="lastName"
                id="lastName"
                value={lastName}
                onChange={e => setLastName(e.target.value)}
                placeholder="Their Name (Last)*"
                required
              />
            </Col>
          </Row>
          <Row>
            <Col
              xs="12"
              md="6"
              lg={{ span: "4", offset: "2" }}
              className={styles.field}
            >
              <input
                type="text"
                name="profession"
                id="profession"
                value={profession}
                onChange={e => setProfession(e.target.value)}
                placeholder="Their Job Title"
              />
            </Col>
            <Col xs="12" md="6" lg={{ span: "4" }} className={styles.field}>
              <input
                type="date"
                name="data"
                id="date"
                value={date}
                onChange={e => setDate(e.target.value)}
                placeholder="Date of Passing"
              />
            </Col>
          </Row>
          <Row>
            <Col
              xs="12"
              lg={{ span: "8", offset: "2" }}
              className={styles.field}
            >
              <textarea
                name="theirstory"
                id="theirstory"
                value={story}
                onChange={e => setStory(e.target.value)}
                placeholder="Their Story"
              />
            </Col>
          </Row>
          <Row>
            <Col
              xs="12"
              md="6"
              lg={{ span: "4", offset: "2" }}
              className={styles.field}
            >
              <input
                type="text"
                id="loadImage"
                value="Upload Their Image"
                placeholder="Upload Their Image"
                style={{ display: !image ? "block" : "none" }}
                onClick={e => imageInput.current.click()}
                readOnly
              />

              <input
                ref={imageInput}
                type="file"
                name="image"
                id="image"
                style={{ display: image ? "block" : "none" }}
                onChange={e => setImage(e.target.files[0])}
                placeholder="Upload Their Image"
                accept="image/*"
              />
            </Col>
            <Col xs="12" md="6" lg={{ span: "4" }} className={styles.field}>
              <input
                type="text"
                name="email"
                id="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                placeholder="Your Email*"
                required
              />
            </Col>
          </Row>
          <Row>
            <Col
              xs="12"
              md="6"
              lg={{ span: "4", offset: "2" }}
              className={styles.field}
            >
              <input
                type="text"
                name="yourFirst"
                id="yourFirst"
                value={yourFirst}
                onChange={e => setYourFirst(e.target.value)}
                placeholder="Your Name (First)*"
                required
              />
            </Col>
            <Col xs="12" md="6" lg={{ span: "4" }} className={styles.field}>
              <input
                type="text"
                name="yourLast"
                id="yourLast"
                value={yourLast}
                onChange={e => setYourLast(e.target.value)}
                placeholder="Your Name (Last)*"
                required
              />
            </Col>
          </Row>
          <Row>
            <Col
              xs="12"
              md="6"
              lg={{ span: "4", offset: "2" }}
              className={styles.field}
            >
              <input
                type="text"
                name="relationship"
                id="relationship"
                value={relationship}
                onChange={e => setRelationship(e.target.value)}
                placeholder="Relationship to Deceased"
              />
            </Col>
            <Col xs="12" md="6" lg={{ span: "4" }} className={styles.field}>
              <Row className={`${styles.field} ${styles.submitButton}`}>
                <Col>
                  <input
                    type="submit"
                    value="Submit"
                    className="special"
                    onClick={submitForm}
                  />
                  <span style={{ padding: "2px 30px 0" }}>
                    <Arrow
                      direction="right"
                      onClick={submitForm}
                      width="30px"
                    />
                  </span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>
                    By submitting someone’s information, you are agreeing to
                    allow us to display their information publicly.
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </form>
      </Container>
    </div>
  )

  switch (submitStatus) {
    case "success": // A successful submission.
      return (
        <div>
          <Container
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "space-evenly",
              height: "100vh",
              flexDirection: "column",
            }}
          >
            <Row style={{ flexDirection: "row" }}>
              <h1 style={{ textAlign: "center", width: "100%" }}>Thank You.</h1>
              <Col
                xs="12"
                lg={{ span: "8", offset: "2" }}
                className={styles.field}
              >
                <p>
                  Thank you for sharing a piece of their legacy with us. Someone
                  from our memorial team may reach out by email to discuss
                  additional ways to honor their legacy.
                </p>
              </Col>
            </Row>
            <p>
              Click to add another person{" "}
              <span>
                <BsArrowClockwise
                  size="3rem"
                  onClick={resetForm}
                />
              </span>
            </p>
          </Container>
        </div>
      )
    case "loading": // Just submitted, no response yet.
      return (
        <div>
          <Container
            style={{
              height: "100vh",
              textAlign: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <p>Please wait. Your message is being submitted.</p>
          </Container>
        </div>
      )
    case "error": // Something went wrong.
      return (
        <div>
          <Container
            style={{
              height: "100vh",
              textAlign: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <p>
              There was an error in your submission. Please try again later.
              <span>
                <BsArrowClockwise
                  size="3rem"
                  onClick={resetForm}
                />
                </span>
            </p>
          </Container>
        </div>
      )
    default:
      // No submission, render the form.
      return renderCommentForm
  }
}

export default StoryForm
