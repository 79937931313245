import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Spring } from "react-spring/renderprops"
import { animated, config } from "react-spring"
import Search from "../components/searchContainer"
import { Container, Row, Col } from "react-bootstrap"
import { useMediaQuery } from "react-responsive"
import { StaticImage } from "gatsby-plugin-image"
import StoryForm from "../components/storyForm"
import Arrow from "../components/arrow"
import ClientOnly from "../components/clientOnly"

export default function TheirStories({ data }) {
  var gridImage1 = "../images/Angel_Jurado_Photo_1.jpg"
  var gridImage2 = "../images/Celia_Marcos_Picture_4.jpg"
  var gridImage3 = "../images/Percilla_Vegiga_Tolentino.png"
  var gridImage4 = "../images/Ryan_Salahi.png"
  var gridImage5 = "../images/David_Duran.png"
  var gridImage6 = "../images/Brittany_Bruner_Ringo_Picture_1.jpg"
  var gridImage7 = "../images/Scott_Blanks_Photo_1.jpg"

  const [destination, setDestination] = useState(-1)
  const isDestination = d => {
    return d === destination
  }
  const workers = data.allWpWorker.nodes && data.allWpWorker.nodes.filter(worker => worker.workerData.nonworker !== true)
  const isMobile = useMediaQuery({
    query: "(max-width: 42rem)",
  })
  useEffect(() => {
    window && window.fullpage_api && window.fullpage_api.reBuild()
  }, [])
  return (
    <div>
      <SEO
        title="Their Stories | Forever Essential"
        description="Together we honor the stories of healthcare workers who have lost"
        fbHeadline="Their Stories | Forever Essential"
        fbDescription="Honoring the legacy of healthcare workers lost to COVID-19."
        twShare="Please join me in honoring the legacies of the brave healthcare workers who have lost their lives to COVID-19. #ForeverEssential"
      />
      <ClientOnly>
        <Layout handlerDestination={setDestination}>
          {/* highlight-start */}
          <div
            className="section theirstories_section"
            style={{
              background:
                "linear-gradient(rgba(20,36,51,1), rgba(20,36,51,.2))",
            }}
          >
            <Container>
              <Spring
                delay={isDestination(0) ? 200 : 0}
                to={{
                  opacity: isDestination(0) ? 1 : 0,
                  transform: isDestination(0)
                    ? "translateY(0)"
                    : "translateY(-150px)",
                }}
                config={config.molasses}
              >
                {props => (
                  <animated.div style={{ ...props }}>
                    <h1
                      style={{
                        textAlign: isMobile ? "center" : "left",
                        fontStyle: "italic",
                      }}
                    >
                      Their Stories
                    </h1>
                  </animated.div>
                )}
              </Spring>
              <Spring
                delay={isDestination(0) ? 500 : 0}
                to={{
                  opacity: isDestination(0) ? 1 : 0,
                  transform: isDestination(0)
                    ? "translateY(0)"
                    : "translateY(-200px)",
                }}
                config={config.molasses}
              >
                {props => (
                  <animated.div style={props}>
                    <h2
                      style={{
                        textAlign: isMobile ? "center" : "left",
                      }}
                    >
                      Honoring the legacies of our fallen healthcare workers.
                    </h2>
                  </animated.div>
                )}
              </Spring>
              <Spring
                delay={isDestination(0) ? 1500 : 0}
                to={{
                  opacity: isDestination(0) ? 1 : 0,
                  transform: isDestination(0)
                    ? "translateY(0)"
                    : "translateY(-70px)",
                }}
                config={config.molasses}
              >
                {props => (
                  <div
                    style={{
                      ...props,
                      textAlign: "center",
                      marginTop: "15%",
                    }}
                  >
                    <h1>
                      <Arrow
                        direction="down"
                        width="50px"
                        onClick={e => window.fullpage_api.moveSectionDown()}
                      />
                    </h1>
                  </div>
                )}
              </Spring>
            </Container>
          </div>
          <div
            className="section theirstories_section"
            style={{
              background:
                "linear-gradient(rgba(20,36,51,.2), rgba(0,155,165,1))",
            }}
          >
            <Spring
              delay={isDestination(1) ? 2000 : 0}
              to={{
                opacity: isDestination(1) ? 1 : 0,
                transform: isDestination(1)
                  ? "translateY(0)"
                  : "translateY(200px)",
              }}
              config={config.molasses}
            >
              {props => (
                <div style={props}>
                  <Container>
                    <h2
                      style={{
                        textAlign: "center",
                        fontWeight: "normal",
                        paddingTop: isMobile ? "20px" : "",
                      }}
                    >
                      Learn more about the lives and legacies of healthcare
                      workers who we’ve lost to COVID-19.
                    </h2>
                  </Container>
                </div>
              )}
            </Spring>
            <Container fluid={isMobile}>
              <Row noGutters={isMobile}>
                <Spring
                  delay={isDestination(1) ? 2500 : 0}
                  to={{
                    opacity: isDestination(1) ? 1 : 0,
                    transform: isDestination(1)
                      ? "translateX(0)"
                      : "translateX(-50px)",
                  }}
                  config={config.molasses}
                >
                  {props => (
                    <Col xs="2" lg="2" style={props}>
                      <StaticImage
                        src={gridImage6}
                        alt="background"
                        imgStyle={{
                          width: "98%",
                          marginTop: "50px",
                        }}
                        style={{height: isMobile ? "200px" : "400px" }}
                      />
                    </Col>
                  )}
                </Spring>
                <Spring
                  delay={isDestination(1) ? 2500 : 0}
                  to={{
                    opacity: isDestination(1) ? 1 : 0,
                    transform: isDestination(1)
                      ? "translateX(0)"
                      : "translateX(-250px)",
                  }}
                  config={config.molasses}
                >
                  {props => (
                    <Col xs="2" lg="2" style={props}>
                      <StaticImage
                        src={gridImage4}
                        alt="background"
                        imgStyle={{
                          width: "98%",
                          marginTop: "30px",
                          marginBottom: "20px",
                        }}
                        style={{height: isMobile ? "125px" : "250px"}}
                      />
                      <StaticImage
                        src={gridImage7}
                        alt="background"
                        imgStyle={{
                          width: "98%",
                        }}
                        style={{height: isMobile ? "125px" : "250px"}}
                      />
                    </Col>
                  )}
                </Spring>
                <Spring
                  delay={isDestination(1) ? 2500 : 0}
                  to={{
                    opacity: isDestination(1) ? 1 : 0,
                    transform: isDestination(1)
                      ? "translateY(0)"
                      : "translateY(300px)",
                  }}
                  config={config.molasses}
                >
                  {props => (
                    <Col xs="4" lg="4" style={props}>
                      <StaticImage
                        src={gridImage2}
                        alt="grid 3"
                        imgStyle={{
                          width: "98%",
                        }}
                        style={{height: isMobile ? "300px" : "600px"}}
                      />
                    </Col>
                  )}
                </Spring>
                <Spring
                  delay={isDestination(1) ? 2500 : 0}
                  to={{
                    opacity: isDestination(1) ? 1 : 0,
                    transform: isDestination(1)
                      ? "translate(0)"
                      : "translateX(250px)",
                  }}
                  config={config.molasses}
                >
                  {props => (
                    <Col xs="2" lg="2" style={props}>
                      <StaticImage
                        src={gridImage1}
                        alt="grid 4"
                        imgStyle={{
                          width: "98%",
                          marginTop: "30px",
                          marginBottom: "20px",
                        }}
                        style={{height: isMobile ? "125px" : "250px"}}
                      />
                      <StaticImage
                        src={gridImage5}
                        alt="grid 5"
                        imgStyle={{
                          width: "98%",
                        }}
                        style={{height: isMobile ? "125px" : "250px"}}
                      />
                    </Col>
                  )}
                </Spring>
                <Spring
                  delay={isDestination(1) ? 2500 : 0}
                  to={{
                    opacity: isDestination(1) ? 1 : 0,
                    transform: isDestination(1)
                      ? "translateX(0)"
                      : "translateX(150px)",
                  }}
                  config={config.molasses}
                >
                  {props => (
                    <Col xs="2" lg="2" style={props}>
                      <StaticImage
                        src={gridImage3}
                        alt="grid 7"
                        imgStyle={{
                          width: "98%",
                          marginTop: "50px",
                        }}
                        style={{height: isMobile ? "175px" : "350px"}}
                      />
                    </Col>
                  )}
                </Spring>
              </Row>
            </Container>
          </div>
          <div
            className={`section theirstories_section fp-auto-height`}
            style={{
              background:
                "linear-gradient( rgba(0,155,165,1) 0%, rgba(255,255,255,1) 20%, rgba(255,255,255,1) 80%, rgba(0,155,165,1) 100%)",
            }}
          >
            <Spring
              delay={isDestination(2) ? 200 : 0}
              to={{
                opacity: isDestination(2) ? 1 : 0,
                transform: isDestination(2)
                  ? "translateY(0)"
                  : "translateY(40px)",
              }}
              config={config.molasses}
            >
              {props => (
                <animated.div style={{ ...props }}>
                  <div style={{ minHeight: "120vh", padding: "40px 0" }}>
                    {workers && workers.length > 0 ? (
                      <Search workerList={workers} />
                    ) : (
                      <div />
                    )}
                  </div>
                </animated.div>
              )}
            </Spring>
          </div>
          <div
            className="section theirstories_section"
            style={{
              background:
                "linear-gradient(180deg, rgba(0,155,165,1) 0%, rgba(3,140,150,1) 2%, rgba(20,36,51,.9) 100%)",
              paddingTop: "20px",
            }}
          >
            <Spring
              delay={isDestination(3) ? 1500 : 0}
              to={{
                opacity: isDestination(3) ? 1 : 0,
                transform: isDestination(3)
                  ? "translateY(0)"
                  : "translateY(40px)",
              }}
              config={config.molasses}
            >
              {props => (
                <animated.div style={{ ...props }}>
                  <StoryForm />
                </animated.div>
              )}
            </Spring>
          </div>
        </Layout>
      </ClientOnly>
    </div>
  )
}

export const pageQuery = graphql`
  query MyQuery {
    allWpWorker(sort: { fields: [title], order: ASC }) {
      nodes {
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 250, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
        workerData {
          job
          city
          nonworker
        }
      }
    }
  }
`
